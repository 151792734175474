let menuStatus = false;

document.getElementById("menu-btn").addEventListener("click", (evt)=>{
    evt.preventDefault();
    toggleMenu();
})

function toggleMenu(){

    const menuBar = document.getElementById("nav-menu")
    const menuBtn = document.getElementById("menu-btn");
    if(menuStatus) {
        menuBar.classList.remove("is-active");
        menuBtn.classList.remove("is-active")
    }
    else{
        menuBar.classList.add("is-active");
        menuBtn.classList.add("is-active")
    }        
    menuStatus = !menuStatus;
    menuBtn.setAttribute("aria-expanded", menuStatus.toString());

}